<template>
	<div>
		<div v-if="loading" class="col-md-12">
			<div class="card">
				<div class="content" style="display:flex; justify-content:center; align-items:center; height:200px;">
					<p>Laddar...</p>
				</div>
			</div>
		</div>

		<div v-else class="col-md-12" v-for="post in posts" :key="post.id">
			<div class="box" style="box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);">
				<div class="row">
					<h3 style="padding-left:34px;" v-text="post.subject"></h3>
					<p class="text-sm text-gray-500 pull-right" style="text-transform: capitalize;"
						v-text="'Publicerat ' + getDate(post.sent_at)"></p>
				</div>

				<div v-html="post.body"></div>
			</div>
		</div>

		<div v-if="!loading && posts.length === 0" class="col-md-12">
			<div class="card">
				<div class="content">
					<div class="content" style="display:flex; justify-content:center; align-items:center; height:200px;">
						<p class="lead">Det finns inga publicerade nyheter</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {

	data() {
		return {
			posts: [],
			loading: true
		}
	},

	mounted() {
		this.getNews()
	},

	methods: {
		getNews() {
			axios.get('bulkemail/guest/index')
				.then(response => {
					this.posts = response.data
					this.loading = false
				})
		},

		getDate(date) {
			return moment(date).format('D MMMM YYYY')
		}
	}
}
</script>