<template>
    <div>
        <div class="row" style="margin-top: 15px">
            <div class="12u 12u(mobilep)">
                <select v-model="selectedRegion_id" id="region_id" name="region_id"
                    :class="{ 'has-error': regionErrors.length > 0 }">
                    <option value="null" disabled="disabled">
                        Klicka och välj ansvarigt Hushållningssällskap
                    </option>
                    <option v-for="region in regions" :key="region.id" :value="region.id" v-text="region.title"></option>
                </select>

                <span v-if="regionErrors.length > 0" class="help-block" v-text="regionErrors[0]"></span>
            </div>
        </div>
        <div class="row" style="margin-top: 15px">
            <div class="12u 12u(mobilep)">
                <h4 class="mb-1 text-3xl font-extralight">
                    Markera önskad tjänst eller tjänster
                </h4>
            </div>
            <div v-if="selectedRegion_id">
                <div v-for="service in regionServices" :key="service.id" class="12u 12u(mobilep)" style="margin-top: 5px">
                    <input :id="'service-' + service.id" :value="service.id" v-model="selectedServices" type="checkbox"
                        name="services[]" />
                    <label :for="'service-' + service.id" v-text="service.title"></label>
                    <div v-if="
                        service.child_services &&
                        service.child_services.length
                    " style="margin-left: 4rem">
                        <div v-for="child in service.child_services" :key="child.id" class="12u 12u(mobilep)"
                            style="margin-top: 5px">
                            <input :id="'service-' + child.id" v-model="selectedServices" :value="child.id" type="checkbox"
                                name="services[]" />
                            <label :for="'service-' + child.id" v-text="child.title"></label>
                        </div>
                    </div>
                </div>
                <div v-if="regionServices.length == 0" class="12u 12u(mobilep)" style="margin-top: 5px">
                    <p>
                        Inga tjänster finns tillgängliga i den valda regionen.
                        Skriv ett meddelande i rutan nedan och beskriv där ert
                        ärende.
                    </p>
                </div>
            </div>
            <div v-else>
                <i>Du måste välja region innan du kan välja tjänster</i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["regions", "regionErrors", "oldRegion", "oldServices"],

    data: function () {
        return {
            selectedRegion_id: null,
            selectedServices: [],
        };
    },

    mounted() {
        this.selectedRegion_id = this.oldRegion;
        if (this.oldServices && this.oldServices.length) {
            this.selectedServices = this.oldServices.map((id) => parseInt(id));
        }
    },

    computed: {
        regionServices() {
            if (this.selectedRegion_id) {
                return this.regions.find((r) => r.id == this.selectedRegion_id)
                    .services;
            }
            return [];
        },
    },

    watch: {
        selectedServices(newVal, oldVal) {
            // An id was added
            let newIdAdded = newVal.length > oldVal.length;
            let id;
            let foundParent;
            let copy = [...this.selectedServices];
            if (newIdAdded) {
                id = newVal.filter((newId) => !oldVal.includes(newId))[0]; // An id was removed
            } else {
                id = oldVal.filter(
                    (missingId) => !newVal.includes(missingId)
                )[0];
            }

            foundParent = this.regionServices.find((s) => s.id == id);

            // Check if the added or removed id was a "parent"-service
            if (foundParent) {
                if (
                    foundParent.child_services &&
                    foundParent.child_services.length
                ) {
                    // If the service was selected but hade no children selected, select them all
                    if (newIdAdded) {
                        if (
                            foundParent.child_services.every(
                                (c) => !copy.includes(c.id)
                            )
                        ) {
                            foundParent.child_services.forEach((c) => {
                                copy.push(c.id);
                            });
                        }
                        // If the service was deselected and had children selected, deselect them
                    } else {
                        if (
                            foundParent.child_services.some((c) =>
                                copy.includes(c.id)
                            )
                        ) {
                            let child_ids = foundParent.child_services.map(
                                (c) => c.id
                            );
                            copy = copy.filter(
                                (s_id) => !child_ids.includes(s_id)
                            );
                        }
                    }
                }

                // Otherwise find that parent of the service that was selected
            } else {
                foundParent = this.regionServices.find((s) =>
                    s.child_services.some((c) => c.id == id)
                );
                if (foundParent) {
                    // If the parent was not selected, select it
                    if (newIdAdded && !copy.includes(foundParent.id)) {
                        copy.push(foundParent.id);
                        // If the parent has no children selected but it is selected, then deselect it
                    } else if (
                        !newIdAdded &&
                        copy.includes(foundParent.id) &&
                        foundParent.child_services.every(
                            (c) => !copy.includes(c.id)
                        )
                    ) {
                        copy = copy.filter((s_id) => s_id != foundParent.id);
                    }
                }
            }
            if (
                copy.length != this.selectedServices.length ||
                !this.selectedServices.every((s_id) => copy.includes(s_id))
            ) {
                this.selectedServices = copy;
            }
        },
    },

    methods: {},
};
</script>
