import axios from "axios";
window.axios = axios;

import Vue from "vue";
window.Vue = Vue;

import moment from "moment";
window.moment = moment;
window.moment.locale("sv");

import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
import "@fortawesome/fontawesome-free/css/all.css";
import "../css/main.css";

if (process.env.NODE_ENV === "production") {
    axios.defaults.baseURL = "https://api.markkartering.se/api/v1";
} else {
    axios.defaults.baseURL = import.meta.env.VITE_API_URL + "api/v1";
}
import GuestNews from "./components/guest-news.vue";
import Services from "./components/services.vue";
import MobileNav from "./components/mobile-nav.vue";
import TabsBar from "@/components/utils/TabsBar.vue";

Vue.component("guest-news", GuestNews);
Vue.component("services", Services);
Vue.component("mobile-nav", MobileNav);
Vue.component("TabsBar", TabsBar);

new Vue({
    el: "#app",
});
