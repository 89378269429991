<template>
	<div>
		<button @click="show = true" id="sidePanelButton" class="open-sidebar-button"><i class="fa fa-bars"></i></button>

		<transition name="sidebar-slide">
			<div v-if="show" id="navPanel" class="">
				<nav>
					<button @click="show = false" class="close-sidebar-button"><i class="fa fa-times"></i></button>
					<a v-for="link in links" :key="link.title" :class="[link.child ? 'depth-1' : 'depth-0', 'link']"
						:href="link.url">
						<span :class="link.child ? 'indent-1' : 'indent-0'" />
						{{ link.title }}
					</a>
				</nav>
			</div>
		</transition>
	</div>
</template>
<script>
export default {

	props: ['routes'],

	data() {
		return {
			show: false
		}
	},

	computed: {
		links() {
			return this.routes.reduce((newLinks, route) => {
				if (route.type == 'link') {
					newLinks.push({ title: route.title, url: route.url })
				} else {
					newLinks.push({ title: route.title, url: '#' })
					route.routes.forEach(r => {
						newLinks.push({ title: r.title, url: r.url, child: true })
					});
				}
				return newLinks
			}, [])
		}
	}
}
</script>
<style scoped>
.open-sidebar-button {
	background: rgba(192, 192, 192, .75);
	border-radius: 6px;
	color: #fff;
	content: '\f0c9';
	display: block;
	font-size: 16px;
	height: 2.25em;
	left: 0.5em;
	line-height: 2.25em;
	position: absolute;
	text-align: center;
	top: 0.5em;
	width: 3.5em;
	-webkit-font-smoothing: antialiased;
	cursor: pointer;
	border: 0;
	outline: 0;
}

.close-sidebar-button {
	position: absolute;
	right: 10px;
	top: 10px;
	-moz-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
	appearance: none;
	-moz-transition: background-color .2s ease-in-out, color .2s ease-in-out;
	-webkit-transition: background-color .2s ease-in-out, color .2s ease-in-out;
	-ms-transition: background-color .2s ease-in-out, color .2s ease-in-out;
	transition: background-color .2s ease-in-out, color .2s ease-in-out;
	background-color: #666;
	border-radius: 6px;
	border: 0;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-weight: 400;
	height: 2em;
	line-height: 2em;
	padding: 0px 1em;
	text-align: center;
	text-decoration: none;
	white-space: nowrap;
}

.close-sidebar-button:hover {
	background-color: #737373;
}

.sidebar-slide-enter-from {
	-moz-transform: translateX(0px);
	-webkit-transform: translateX(0px);
	-ms-transform: translateX(0px);
	transform: translateX(0px);
}

.sidebar-slide-enter-active,
.sidebar-slide-leave-to {
	-moz-transform: translateX(-275px);
	-webkit-transform: translateX(-275px);
	-ms-transform: translateX(-275px);
	transform: translateX(-275px);
}
</style>